export const routes = [
    {
        path: '/attendances',
        name: 'attendances.browse',
        component: () => import(/* webpackChunkName: "AttendancesBrowse" */ '@/views/app/AttendancesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/attendances/:id/clone',
        name: 'attendances.clone',
        component: () => import(/* webpackChunkName: "AttendancesActions" */ '@/views/app/AttendancesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/attendances/create',
        name: 'attendances.create',
        component: () => import(/* webpackChunkName: "AttendancesActions" */ '@/views/app/AttendancesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/attendances/:id/edit',
        name: 'attendances.edit',
        component: () => import(/* webpackChunkName: "AttendancesActions" */ '@/views/app/AttendancesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/attendances/:id/delete',
        name: 'attendances.delete',
        component: () => import(/* webpackChunkName: "AttendancesActions" */ '@/views/app/AttendancesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/attendances/:id/show',
        name: 'attendances.show',
        component: () => import(/* webpackChunkName: "AttendancesActions" */ '@/views/app/AttendancesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]